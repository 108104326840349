import bio from "../Images/bio.webp";
import cal from "../Images/cal-1.webp";
import eco from "../Images/eco.webp";
import history from "../Images/history.webp";
import science from "../Images/science.webp";
import physics from "../Images/physics.webp";
import busi from "../Images/busi.webp";
import political from "../Images/political.webp";
import hindi from "../Images/hindi.webp";
import english from "../Images/english.webp";
import comp from "../Images/comp-1.webp";
import chem from "../Images/chem-1.webp";
import french from "../Images/french.webp";
import acc from "../Images/acc.webp";
import geography from "../Images/geography.webp";
import all from "../Images/all.jpg";
import new1 from "../Images/new1.png";

import mapimg from "../Images/mapImg.jpg";
import mathImg from "../Images/mathImg.png";
import back from "../Images/back.webp";
import new2 from "../Images/new2.png";

export const SecondSlide = [
  {
    image: all,
  },
  {
    image: new2,
  },
  {
    image: new1,
  },

  {
    image: mathImg,
  },
  {
    image: mapimg,
  },
];

export const SubjectData = [
  {
    image: cal,
    title: "Mathematics",
  },
  {
    image: bio,
    title: "Biology",
  },
  {
    image: eco,
    title: "Economics",
  },
  {
    image: history,
    title: "History",
  },
  {
    image: science,
    title: "Science",
  },
  {
    image: physics,
    title: "Physics",
  },
  {
    image: busi,
    title: "Business Studies",
  },
  {
    image: political,
    title: "Political Science",
  },
  {
    image: hindi,
    title: "Hindi",
  },
  {
    image: english,
    title: "English",
  },
  {
    image: chem,
    title: "Chemistry",
  },
  {
    image: french,
    title: "French",
  },
  {
    image: comp,
    title: "Computer Science",
  },
  {
    image: acc,
    title: "Accounts",
  },
  {
    image: geography,
    title: "Geography",
  },
  {
    image:
      "https://images.news18.com/ibnlive/uploads/2022/08/world-sanskrit-day.jpg",
    title: "Sanskrit",
  },
];

export const gradeData = [
  {
    image:
      "https://img.freepik.com/premium-photo/back-school-student-poster-back-school-images-back-school-banner-students-quotes_1190219-4095.jpg?size=626&ext=jpg&ga=GA1.1.500536780.1722482090&semt=ais_hybrid",
    title: "Grade 1st to 5th",
  },
  {
    image:
      "https://img.freepik.com/free-photo/girl-blackboard-showing-thumb-up_23-2147663320.jpg?size=626&ext=jpg&ga=GA1.1.500536780.1722482090&semt=ais_hybrid",
    title: "Grade 6th to 8th",
  },
  {
    image:
      "https://img.freepik.com/free-photo/girl-with-books-blackboard_23-2147650749.jpg?ga=GA1.1.500536780.1722482090&semt=ais_hybrid",
    title: "Grade 9th to 10th",
  },
  {
    image:
      "https://img.freepik.com/free-photo/good-students-class_23-2147650717.jpg?ga=GA1.1.500536780.1722482090&semt=ais_hybrid",
    title: "Grade 11th to 12th",
  },
];

export const TeacherData = [
  {
    image: back,
    // rating: 7.8,
    Edu: "B.Com , PHD",
    title: "Gulshan kumar",
    year: "5 Year Experience",
  },
  {
    image:
      "https://digitallearning.eletsonline.com/wp-content/uploads/2017/02/school-girls.jpg",
    Edu: "B.Com , PHD",
    title: "Shivang kumar",
    year: "5 Year Experience",
  },

  {
    image: mathImg,
    Edu: "B.Com , PHD",
    title: "Vipin kumar",
    year: "5 Year Experience",
  },
  {
    image: mapimg,
    Edu: "B.Com , PHD",
    title: "Copy Cat",
    year: "5 Year Experience",
  },

  {
    image: all,
    Edu: "B.Com , PHD",
    title: "Manish kumar",
    year: "5 Year Experience",
  },
];
