import { Heading, Box, Text, Flex, Link as ChakarLink } from "@chakra-ui/react";
import ListView from "../Components/ListView";
import { Link } from "react-router-dom";
import { intellectualProperty, paymentAndFee, serviceUser } from "../constants";

const TermOfService = () => {
  return (
    <Box
      mt='120px'
      w={"100%"}
      px={"20px"}
    >
      {/* heading */}
      <Box>
        <Heading
          as='h1'
          w={"fit-content"}
          mx='auto'
          textAlign={"center"}
        >
          Terms of Service
        </Heading>
        <Text
          as='p'
          w={{ base: "100%", lg: "60%", md: "80%" }}
          textAlign={"center"}
          mx='auto'
        >
          These Terms of Service govern your access to and use of the StudySpot
          website{" "}
          <a
            href='https://www.studyspotindia.com'
            target='_blank'
            rel='noopener noreferrer'
          >
            www.studyspotindia.com
          </a>{" "}
          , operated by StudySpot. By accessing or using the Service, you agree
          to be bound by these Terms.
        </Text>
      </Box>

      {/* content */}
      <Flex
        w={{ base: "100%", lg: "60%", md: "80%" }}
        mx='auto'
        mt='40px'
        direction='column'
        gap={"2rem"}
      >
        <ListView
          heading='Use of the Service'
          data={serviceUser}
        />
        <ListView
          heading='Payment and Fees'
          data={paymentAndFee}
        />
        <ListView
          heading='3. Intellectual Property'
          data={intellectualProperty}
        />

        <ListView
          heading={"Governing Law"}
          subHeading={
            "These Terms shall be governed by and construed in accordance with the laws of Indian Jurisdiction, without regard to its conflict of law principles."
          }
        />

        <Box>
          <Box>
            <Heading
              as='h3'
              fontSize={"2xl"}
            >
              Contact us
            </Heading>
            <Text
              fontSize={"16px"}
              my={"1rem"}
            >
              If you have any questions or concerns about this Privacy Policy or
              our practices regarding your information, please contact us at{" "}
              <ChakarLink
                as={Link}
                to='mailto:studyspotindia@gmail.com'
              >
                <strong>studyspotindia@gmail.com</strong>
              </ChakarLink>
            </Text>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default TermOfService;
