export const AUTH_STUDENT_SIGNUP_REQUEST = "AUTH_STUDENT_SIGNUP_REQUEST";
export const AUTH_STUDENT_SIGNUP_SUCCESS = "AUTH_STUDENT_SIGNUP_SUCCESS";
export const AUTH_STUDENT_SIGNUP_ERROR = "AUTH_STUDENT_SIGNUP_ERROR";

export const AUTH_STUDENT_LOGIN_REQUEST ="AUTH_STUDENT_LOGIN_REQUEST" ;
export const AUTH_STUDENT_LOGIN_SUCCESS ="AUTH_STUDENT_LOGIN_SUCCESS" ;
export const AUTH_STUDENT_LOGIN_ERROR   ="AUTH_STUDENT_LOGIN_ERROR"   ;

export const AUTH_STUDENT_CHECK_TOKEN = "AUTH_STUDENT_CHECK_TOKEN"; 
export const AUTH_STUDENT_LOGOUT   ="AUTH_STUDENT_LOGOUT"   ;

export const AUTH_STUDENT_RESET_MESSAGE = "AUTH_STUDENT_RESET_MESSAGE";

//export const GET_SINGLE_STUDENT="GET_SINGLE_STUDENT"
export const GET_SINGLE_STUDENT_REQUEST = "GET_SINGLE_STUDENT_REQUEST";
export const GET_SINGLE_STUDENT_SUCCESS = "GET_SINGLE_STUDENT_SUCCESS";
export const GET_SINGLE_STUDENT_ERROR = "GET_SINGLE_STUDENT_ERROR";

export const GET_ALL_STUDENTS_REQUEST = "GET_ALL_STUDENTS_REQUEST";
export const GET_ALL_STUDENTS_SUCCESS = "GET_ALL_STUDENTS_SUCCESS";
export const GET_ALL_STUDENTS_ERROR = "GET_ALL_STUDENTS_ERROR";

// delete Request
export const DELETE_STUDENT_REQUEST = "DELETE_STUDENT_REQUEST";
export const DELETE_STUDENT_SUCCESS = "DELETE_STUDENT_SUCCESS";
export const DELETE_STUDENT_ERROR = "DELETE_STUDENT_ERROR";


// update Request
export const UPDATE_STUDENT_REQUEST = "UPDATE_STUDENT_REQUEST";
export const UPDATE_STUDENT_SUCCESS = "UPDATE_STUDENT_SUCCESS";
export const UPDATE_STUDENT_ERROR = "UPDATE_STUDENT_ERROR";




//////////////////////// TEACHER SECTION ///////////////////

export const AUTH_TEACHER_SIGNUP_REQUEST = "AUTH_TEACHER_SIGNUP_REQUEST";
export const AUTH_TEACHER_SIGNUP_SUCCESS = "AUTH_TEACHER_SIGNUP_SUCCESS";
export const AUTH_TEACHER_SIGNUP_ERROR = "AUTH_TEACHER_SIGNUP_ERROR";

export const AUTH_TEACHER_LOGIN_REQUEST ="AUTH_TEACHER_LOGIN_REQUEST" ;
export const AUTH_TEACHER_LOGIN_SUCCESS ="AUTH_TEACHER_LOGIN_SUCCESS" ;
export const AUTH_TEACHER_LOGIN_ERROR   ="AUTH_TEACHER_LOGIN_ERROR"   ;

export const AUTH_TEACHER_CHECK_TOKEN = "AUTH_TEACHER_CHECK_TOKEN"; 
export const AUTH_TEACHER_LOGOUT   ="AUTH_TEACHER_LOGOUT"   ;

export const AUTH_TEACHER_RESET_MESSAGE = "AUTH_TEACHER_RESET_MESSAGE";

export const GET_SINGLE_TEACHER="GET_SINGLE_TEACHER"

export const GET_ALL_TEACHERS_REQUEST = "GET_ALL_TEACHERS_REQUEST";
export const GET_ALL_TEACHERS_SUCCESS = "GET_ALL_TEACHERS_SUCCESS";
export const GET_ALL_TEACHERS_ERROR = "GET_ALL_TEACHERS_ERROR";

// DELETE t TEACHERS
export const DELETE_TEACHER_REQUEST = "DELETE_TEACHER_REQUEST";
export const DELETE_TEACHER_SUCCESS = "DELETE_TEACHER_SUCCESS";
export const DELETE_TEACHER_ERROR = "DELETE_TEACHER_ERROR";

// update Request
export const UPDATE_TEACHER_REQUEST = "UPDATE_TEACHER_REQUEST";
export const UPDATE_TEACHER_SUCCESS = "UPDATE_TEACHER_SUCCESS";
export const UPDATE_TEACHER_ERROR = "UPDATE_TEACHER_ERROR";




// For all perople 

export const RESET_AUTH_MESSAGE = 'RESET_AUTH_MESSAGE';


