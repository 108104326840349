import { Heading, Box, Text, Flex, Link as ChakarLink } from "@chakra-ui/react";
import ListView from "../Components/ListView";
import { Link } from "react-router-dom";
import {
  dataSecurity,
  infoShare,
  infoUse,
  personalInfos,
  yourChoice,
} from "../constants";

const PrivacyPolicy = () => {
  return (
    <Box
      mt='120px'
      w={"100%"}
      px={"20px"}
    >
      {/* heading */}
      <Box>
        <Heading
          as='h1'
          w={"fit-content"}
          mx='auto'
          textAlign={"center"}
        >
          Privacy Policy
        </Heading>
        <Text
          as='p'
          w={{ base: "100%", lg: "60%", md: "80%" }}
          textAlign='center'
          mx='auto'
        >
          At StudySpot India Private Limited, we understand the importance of
          privacy and are committed to protecting your personal information.
          This Privacy Policy explains how we collect, use, and protect your
          data when you use our website and services.
        </Text>
      </Box>

      {/* content */}
      <Flex
        w={{ base: "100%", lg: "60%", md: "80%" }}
        mx='auto'
        mt='40px'
        direction='column'
        gap={"2rem"}
      >
        <ListView
          heading='Information We Collect'
          subHeading='We collect information to provide better services to all our users.This may include:'
          data={personalInfos}
        />
        <ListView
          heading='How We Use Your Information'
          subHeading='We use the information we collect to:'
          data={infoUse}
        />
        <ListView
          heading='Information Sharing'
          subHeading='We do not sell, trade, or otherwise transfer your personal information to third parties without your consent. However, we may share information in the following circumstances:'
          data={infoShare}
        />
        <ListView
          heading={"Your Choices"}
          data={yourChoice}
        />
        <ListView
          heading={"Data Security"}
          data={dataSecurity}
        />
        <ListView
          heading={"Third-Party Disclosure"}
          subHeading={
            "We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as required by law or to facilitate our tutoring services."
          }
        />

        <ListView
          heading={"Changes to this Privacy Policy"}
          subHeading={
            "We may update this Privacy Policy from time to time, and any changes will be posted on this page. We encourage you to review this Privacy Policy periodically for any updates."
          }
        />

        <Box>
          <Box>
            <Heading
              as='h3'
              fontSize={"2xl"}
            >
              Contact us
            </Heading>
            <Text
              fontSize={"16px"}
              my={"1rem"}
            >
              If you have any questions or concerns about this Privacy Policy or
              our practices regarding your information, please contact us at{" "}
              <ChakarLink
                as={Link}
                to='mailto:studyspotindia@gmail.com'
              >
                <strong>studyspotindia@gmail.com</strong>
              </ChakarLink>
            </Text>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default PrivacyPolicy;
