export const serviceUser = [
  {
    bold: "Eligibility",
    content:
      "You must be at least 16 years old to use the Service. By using the Service, you represent and warrant that you are at least 16 years old.",
  },
  {
    bold: "Registration",
    content:
      " In order to access certain features of the Service, you may be required to register for an account. When registering, you agree to provide accurate and complete information and to keep your account information updated.",
  },
  {
    bold: "User Content",
    content:
      "You are solely responsible for any content you submit, post, or display on or through the Service ('User Content'). You agree not to submit User Content that is unlawful, defamatory, infringing, obscene, or otherwise objectionable",
  },
];

export const intellectualProperty = [
  {
    bold: "Ownership",
    content:
      "The Service and its original content, features, and functionality are owned by StudySpot and are protected by copyright, trademark, and other intellectual property laws.",
  },
  {
    bold: "License",
    content:
      "Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-transferable license to access and use the Service for your personal and non-commercial use",
  },
];

export const paymentAndFee = [
  {
    bold: "Payment",
    content:
      "Certain features of the Service may require payment of fees. By using these features, you agree to pay all applicable fees in accordance with the pricing and payment terms disclosed to you at the time of purchase.",
  },
  {
    bold: "Refunds",
    content:
      "All fees are non-refundable unless otherwise expressly stated in writing by us.",
  },
];

// privacy policy
export const personalInfos = [
  {
    bold: "Personal Information",
    content:
      "When you register on our platform or use our services, we may collect personal information such as your name, address, contact details, and educational background.",
  },
  {
    bold: "Academic Information",
    content:
      "To provide personalized tutoring services, we may collect information about your educational preferences, learning goals, and academic performance.",
  },
  {
    bold: "Payment Details",
    content:
      "We collect payment information such as credit card details or other financial information to process payments for tutoring services.",
  },
];

export const infoUse = [
  {
    bold: "Providing Tutoring Services",
    content:
      "We use your information to match you with qualified tutors who can meet your educational needs and preferences.",
  },
  {
    bold: "Personalization",
    content:
      "We may use your academic information to customize tutoring sessions and educational materials to enhance your learning experience.",
  },
  {
    bold: "Communication",
    content:
      "We may use your contact information to send important updates, notifications, or promotional offers related to our services.",
  },
  {
    bold: "Improving Our Services",
    content:
      "We analyze usage data to understand how our platform is used and to make improvements that enhance the quality of our tutoring services.",
  },
];

export const infoShare = [
  {
    bold: "Service Providers",
    content:
      "We may engage third-party service providers to assist us in providing the Service, such as hosting providers or analytics services. These service providers may have access to your information only to perform tasks on our behalf and are obligated not to disclose or use it for any other purpose.",
  },
  {
    bold: "Legal Compliance",
    content:
      "We may disclose your information if we believe it is necessary to comply with applicable laws, regulations, or legal processes, or to protect the rights, property, or safety of StudySpot, our users, or others.",
  },
];

export const dataSecurity = [
  {
    content:
      "We employ industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.",
  },
  {
    content:
      "However, please note that no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.",
  },
];

export const yourChoice = [
  {
    content:
      "You have the right to access, update, or delete your personal information stored on our platform. You can also choose to opt out of receiving promotional communications from us.",
  },
  {
    content:
      "Please note that certain information may be required to access and use our tutoring services, and deleting such information may limit your ability to use our platform effectively.",
  },
];

// refund policy
export const refundEligibility = [
  {
    bold: "Service Cancellation by Client",
    content: [
      "If you cancel the service before the commencement of the first paid session, you are eligible for a full refund.",
      "If you cancel the service after the first paid session but before the completion of the second paid session, you are eligible for a refund of 75% of the paid amount.",
      "No refunds will be issued after the completion of the second paid session.",
    ],
  },
  {
    bold: "Service Cancellation by STUDYSPOT INDIA PVT Ltd",
    content:
      "If STUDYSPOT INDIA PVT Ltd cancels the service due to unforeseen circumstances, a refund for any uncompleted sessions will be provided on a pro-rata basis.",
  },
  {
    bold: "Dissatisfaction with Services",
    content:
      "If you are dissatisfied with the quality of the tutoring service, you may request another tutor within 7 days of the session in question. Refunds will not be considered on this basis as we have provided a free demo class.",
  },
];

export const refundProcess = [
  {
    content:
      'Submit a written request to studyspotindia@gmail.com with the subject line "Refund Request"',
  },
  {
    content:
      "Include your full name, contact information, the reason for the refund request, and any relevant details regarding the service",
  },
  {
    content:
      "Our team will review your request and respond within 7 business days.",
  },
];

export const refundProcessing = [
  {
    content:
      "Approved refunds will be processed within 14 business days from the date of approval.",
  },
  {
    content:
      "Refunds will be issued using the same method of payment used for the original purchase unless otherwise agreed upon.",
  },
  {
    content:
      "Any bank or transaction fees incurred during the refund process will be deducted from the refund amount.",
  },
];

export const nonRefundFee = [
  {
    content: "Any completed tutoring sessions.",
  },
  {
    content: "Administrative and registration fees (if applicable).",
  },
];

export const cancellationPolicy = [
  {
    content:
      "If you need to cancel a scheduled tutoring session, please provide advance notice to your assigned tutor and contact our customer support team as soon as possible.",
  },
  {
    content:
      "Cancellation policies may vary depending on the specific terms agreed upon with your tutor. Please refer to your tutoring agreement for more information.",
  },
];

export const disputeResolution = [
  {
    content:
      "In the event of any disputes regarding refunds or tutoring services, we encourage you to contact our customer support team to resolve the issue amicably.",
  },
  {
    content:
      "If a resolution cannot be reached informally, disputes may be subject to mediation or arbitration in accordance with the terms outlined in the Terms and Conditions.",
  },
];
