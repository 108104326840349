import {
  Box,
  Heading,
  List,
  ListIcon,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { MdCheckCircle } from "react-icons/md";

const ListView = ({
  heading,
  subHeading,
  data,
  icon = MdCheckCircle,
  iconColor = "green.500",
}) => {
  return (
    <Box>
      <Box>
        <Heading
          as='h3'
          fontSize={"2xl"}
        >
          {heading}
        </Heading>
        {subHeading && (
          <Text
            fontSize={"1rem"}
            my='.3rem'
          >
            {subHeading}
          </Text>
        )}
      </Box>

      {/* LIST */}
      {data && (
        <List
          mt='16px'
          spacing={3}
        >
          {data.map(({ bold, content }) => (
            <ListItem>
              <ListIcon
                as={icon}
                color={iconColor}
              />
              {bold && (
                <>
                  <strong>{bold}</strong>:
                </>
              )}{" "}
              {Array.isArray(content) ? (
                <>
                  <UnorderedList
                    ml={38}
                    styleType='lower-roman'
                    spacing={1}
                    my={2}
                  >
                    {content.map((val, idx) => (
                      <ListItem key={idx}>{val}</ListItem>
                    ))}
                  </UnorderedList>
                </>
              ) : (
                content
              )}
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default ListView;
