import { Heading, Box, Text, Flex, Link as ChakarLink } from "@chakra-ui/react";
import ListView from "../Components/ListView";
import { Link } from "react-router-dom";
import {
  cancellationPolicy,
  disputeResolution,
  nonRefundFee,
  refundEligibility,
  refundProcess,
  refundProcessing,
} from "../constants";

const RefundPolicy = () => {
  return (
    <Box
      mt='120px'
      w={"100%"}
      px={"20px"}
    >
      {/* heading */}
      <Box>
        <Heading
          as='h1'
          w={"fit-content"}
          mx='auto'
          textAlign={"center"}
        >
          Refund Policy
        </Heading>
        <Text
          as='p'
          w={{ base: "100%", lg: "60%", md: "80%" }}
          textAlign={"center"}
          mx='auto'
        >
          At STUDYSPOT INDIA PVT Ltd, we strive to provide the highest quality
          educational services through our home tuition programs. We understand
          that there may be circumstances where you may require a refund. This
          policy outlines the conditions under which refunds will be granted.
        </Text>
      </Box>

      {/* content */}
      <Flex
        w={{ base: "100%", lg: "60%", md: "80%" }}
        mx='auto'
        mt='40px'
        direction='column'
        gap={"1.5rem"}
      >
        <ListView
          heading='Demo Class'
          data={[
            {
              content:
                "We offer a free demo class to help you evaluate our tutoring services. This demo class is free of cost and carries no obligation.",
            },
          ]}
        />
        <ListView
          heading='Refund Eligibility'
          subHeading='Refunds will be considered under the following circumstances:'
          data={refundEligibility}
        />
        <ListView
          heading='Refund Process'
          subHeading={"To request a refund, please follow these steps:"}
          data={refundProcess}
        />
        <ListView
          heading='Processing of Refunds'
          data={refundProcessing}
        />
        <ListView
          heading='Non-Refundable Fees'
          data={nonRefundFee}
        />
        <ListView
          heading={"Cancellation Policy"}
          data={cancellationPolicy}
        />
        <ListView
          heading={"Dispute Resolution"}
          data={disputeResolution}
        />

        <ListView
          heading={"Changes to This Policy"}
          subHeading={
            "STUDYSPOT INDIA PVT Ltd reserves the right to modify this Refund Policy at any time. Any changes will be posted on our website with the updated effective date."
          }
        />

        <Box>
          <Box>
            <Heading
              as='h3'
              fontSize={"2xl"}
            >
              Contact us
            </Heading>
            <Text
              fontSize={"16px"}
              my={"1rem"}
            >
              If you have any questions or concerns about this Privacy Policy or
              our practices regarding your information, please contact us at{" "}
              <ChakarLink
                as={Link}
                to='mailto:studyspotindia@gmail.com'
              >
                <strong>studyspotindia@gmail.com</strong>
              </ChakarLink>
            </Text>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default RefundPolicy;
