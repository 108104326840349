import { Box, Heading, Input, Select, Text } from "@chakra-ui/react";
import React from "react";

import "./checkout.css";

const CheckOutPage = () => {
  return (
    <Box
      mt={"80px"}
      minH={"100vh"}
      overflow={"hidden"}
    >
      <div className='mainscreen'>
        <div className='card'>
          <div
            className='leftside'
            style={{
              padding: "1rem",
            }}
          >
            <img
              src='https://img.freepik.com/free-photo/good-students-class_23-2147650717.jpg?ga=GA1.1.500536780.1722482090&semt=ais_hybrid'
              className='product'
              alt='Shoes'
            />
          </div>
          <div className='rightside'>
            <form onSubmit={(e) => e.preventDefault()}>
              <Heading
                as='h1'
                fontSize={"24"}
                color={"blue.600"}
              >
                Payment Information
              </Heading>

              <Box>
                <Box>
                  <Text>Subject</Text>
                  <Input
                    type='text'
                    placeholder='Enter Subject'
                    color={"black"}
                  />
                </Box>
                <Box>
                  <Text>Grade</Text>
                  <Input
                    type='text'
                    placeholder='Enter grade'
                    color={"black"}
                  />
                </Box>
                <Box>
                  <Text>Tuition Fee</Text>
                  <Input
                    type='number'
                    placeholder='eg 10000'
                    color={"black"}
                  />
                </Box>

                <Box>
                  <Text>Select Mode</Text>
                  <Select placeholder='Select select option'>
                    <option value='option1'>Home tuition</option>
                    <option value='option2'>Teacher's tuition</option>
                    <option value='option3'>Online</option>
                  </Select>
                </Box>
              </Box>

              <button
                type='submit'
                className='button'
                style={{
                  marginTop: "30px",
                }}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default CheckOutPage;
