//import { AUTH_STUDENT_ERROR, AUTH_STUDENT_LOGOUT, AUTH_STUDENT_REQUEST, AUTH_STUDENT_SUCCESS,AUTH_STUDENT_SIGNUP_ERROR, AUTH_STUDENT_SIGNUP_REQUEST, AUTH_STUDENT_SIGNUP_SUCCESS, GET_SINGLE_USER  } from "./Auth.actionTypes";
import * as types from "./Auth.actionTypes"
import axios from "axios"
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";


// STUDENT Add authSignup action

export  const authStudentSignup = (signupData) => async (dispatch) => {
    dispatch({ type: types.AUTH_STUDENT_SIGNUP_REQUEST });

    try {
        const response = await axios.post('https://studyspotindia.cyclic.cloud/student/signup', signupData);
        const data = response.data;
       // console.log(data, 'hello signup');  


        dispatch({
            type: types.AUTH_STUDENT_SIGNUP_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const errorMessage = error.response.data.message;
        dispatch({ type: types.AUTH_STUDENT_SIGNUP_ERROR, payload: { message: errorMessage } });
    }
};

// Teacher Signup Section

export const authTeacherSignup = (signupData) => async (dispatch) => {
    dispatch({ type: types.AUTH_TEACHER_SIGNUP_REQUEST });

    try {
        const response = await axios.post('https://studyspotindia.cyclic.cloud/tutor/signup', signupData);
        const data = response.data;
       // console.log(data, 'hello signup');


        dispatch({
            type: types.AUTH_TEACHER_SIGNUP_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const errorMessage = error.response.data.message;
        dispatch({ type: types.AUTH_TEACHER_SIGNUP_ERROR, payload: { message: errorMessage } });
    }
};


//   STUDENT  Login Section /////////////////

export const AuthStudentLogin = (loginData) => async (dispatch) => {

    dispatch({ type: types.AUTH_STUDENT_LOGIN_REQUEST });

    try {
        const response = await axios.post('https://studyspotindia.cyclic.cloud/student/login', loginData, {
            withCredentials: true // This will include cookies in the request
        });
        const data = response.data;
       // console.log(data, 'hello login Check');

        // Calculate the expiration time for one week (7 days)
        const oneWeekFromNow = new Date();
        oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);

        // Set access token in a cookie with 1-minute expiration
        Cookies.set('StudentToken', data.token, { expires: oneWeekFromNow }); 
        const decodedToken = jwt_decode(data.token);
        const userId = decodedToken.userID;
        //console.log(userId,"Login userID")
        Cookies.set('userId', userId, { expires: oneWeekFromNow }); // Set userId in a cookie
        dispatch({
            type: types.AUTH_STUDENT_LOGIN_SUCCESS,
            payload: {
                token: data.token,
                message: data.message,
                //data

            },
        });
    } catch (error) {
        const errorMessage = error.response.data.message;
        dispatch({ type: types.AUTH_STUDENT_LOGIN_ERROR, payload: { message: errorMessage } });
    }
};



//   Teacher  Login Section /////////////////

export const AuthTeacherLogin = (loginData) => async (dispatch) => {

    dispatch({ type: types.AUTH_TEACHER_LOGIN_REQUEST });

    try {
        const response = await axios.post('https://studyspotindia.cyclic.cloud/tutor/login', loginData, {
            withCredentials: true // This will include cookies in the request
        });
        const data = response.data;
        //console.log(data, 'hello login Check');

        // Calculate the expiration time for one week (7 days)
        const oneWeekFromNow = new Date();
        oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);

        // Set access token in a cookie with 1-minute expiration
        Cookies.set('StudentToken', data.token, { expires: oneWeekFromNow }); // Expires in 1 minute

        const decodedToken = jwt_decode(data.token);
        const userId = decodedToken.userID;
        //console.log(userId,"Login userID")
        Cookies.set('userId', userId, { expires: oneWeekFromNow }); // Set userId in a cookie
        dispatch({
            type: types.AUTH_TEACHER_LOGIN_SUCCESS,
            payload: {
                token: data.token,
                message: data.message,
                //data

            },
        });
    } catch (error) {
        const errorMessage = error.response.data.message;
        dispatch({ type: types.AUTH_TEACHER_LOGIN_ERROR, payload: { message: errorMessage } });
    }
};





// Check token presence action
export const checkTokenPresence = () => (dispatch) => {
    const tokenCookie = Cookies.get('StudentToken');
    //console.log(tokenCookie, "baap re baap");
    const isAuthenticated = !!tokenCookie; // Convert to boolean
    dispatch({ type: types.AUTH_STUDENT_CHECK_TOKEN, payload: { isAuthenticated } });
};


/////////////////// STUDENT ACTION START  ////////////////


export const getallStudents = () => async (dispatch) => {

    dispatch({ type: types.GET_ALL_STUDENTS_REQUEST });


    try {
       // console.log(userType, user_id,"chek in action")
      // Make a GET request to your backend to fetch the student data using the user ID
      const response = await axios.get(`https://studyspotindia.cyclic.cloud/student`);
  
      const studentData = response.data; // Assuming the API returns student data
      //console.log(studentData)
      dispatch({ type: types.GET_ALL_STUDENTS_SUCCESS, payload: studentData });

    } catch (error) {
      // Handle any errors here
      const errorMessage = error.response.data.message;
        dispatch({ type: types.GET_ALL_STUDENTS_ERROR, payload: { message: errorMessage } });
    }
  };

  // Action creator to update student

export const updateStudent = (id, formData) => async (dispatch) => {
    dispatch({ type:types.UPDATE_STUDENT_REQUEST });
   // console.log(id,formData,"in Aaction")
  
    try {
      // Make the API call to update the student
      const response = await axios.patch(`https://studyspotindia.cyclic.cloud/student/update/${id}`, formData);
  
      dispatch({
        type:types.UPDATE_STUDENT_SUCCESS,
        //payload: response.data,
      });
    } catch (error) {
      dispatch({
        type:types.UPDATE_STUDENT_ERROR,
        payload: error.message || 'Something went wrong while updating the student.',
      });
    }
  };


  /// DELETE STUDENT Request

 export const deleteStudent = (id) => async (dispatch) => {
   // console.log(id)

    dispatch({type:types.DELETE_STUDENT_REQUEST});
    try {
        const response = await axios.delete(`https://studyspotindia.cyclic.cloud/student/delete/${id}`);
        const deletestudentdata = response.data; // Assuming the API returns student data
       // console.log(deletestudentdata);

        dispatch({ type: types.DELETE_STUDENT_SUCCESS, payload: deletestudentdata });
      } catch (error) {
        const errorMessage = error.response.data.message;

        dispatch({ type: types.DELETE_STUDENT_ERROR, payload: { message: errorMessage } });
      }
  
  }




  /////////////////// TEACHERS ACTION START  ////////////////


export const getallTeachers = () => async (dispatch) => {

    dispatch({ type: types.GET_ALL_TEACHERS_REQUEST });


    try {
       // console.log(userType, user_id,"chek in action")
      // Make a GET request to your backend to fetch the student data using the user ID
      const response = await axios.get(`https://studyspotindia.cyclic.cloud/tutor`);
  
      const teachersData = response.data; // Assuming the API returns student data
      //console.log(teachersData)
      dispatch({ type: types.GET_ALL_TEACHERS_SUCCESS, payload: teachersData });

    } catch (error) {
      // Handle any errors here
      const errorMessage = error.response.data.message;
        dispatch({ type: types.GET_ALL_TEACHERS_ERROR, payload: { message: errorMessage } });
    }
  };



  // Action creator to update teachers

export const updateTeachers = (id, formData) => async (dispatch) => {

  dispatch({ type:types.UPDATE_TEACHER_REQUEST });
 // console.log(id,formData,"in TEACHERS")

  try {
    // Make the API call to update the student
    const response = await axios.patch(`https://studyspotindia.cyclic.cloud/tutor/update/${id}`, formData);

    dispatch({
      type:types.UPDATE_TEACHER_SUCCESS,
      //payload: response.data,
    });
  } catch (error) {
    dispatch({
      type:types.UPDATE_TEACHER_ERROR,
      payload: error.message || 'Something went wrong while updating the teachers.',
    });
  }
};


/// DELETE TEACHERS Request

export const deleteTeachers = (id) => async (dispatch) => {
  //console.log(id)

  dispatch({type:types.DELETE_TEACHER_REQUEST});
  try {
      const response = await axios.delete(`https://studyspotindia.cyclic.cloud/tutor/delete/${id}`);
      const delete_teachersdata = response.data; // Assuming the API returns student data
     // console.log(delete_teachersdata);

      dispatch({ type: types.DELETE_TEACHER_SUCCESS, payload: delete_teachersdata });
    } catch (error) {
      const errorMessage = error.response.data.message;

      dispatch({ type: types.DELETE_TEACHER_ERROR, payload: { message: errorMessage } });
    }

}











  // alag thing



export const getSingleStudent = (userType, user_id) => async (dispatch) => {

    dispatch({ type: types.GET_SINGLE_STUDENT_REQUEST });


    try {
       // console.log(userType, user_id,"chek in action")
      // Make a GET request to your backend to fetch the student data using the user ID
      const response = await axios.get(`https://studyspotindia.cyclic.cloud/${userType}/singleuser/${user_id}`);
  
      const studentData = response.data; // Assuming the API returns student data
     // console.log(studentData)

      dispatch({ type: types.GET_SINGLE_STUDENT_SUCCESS, payload: studentData });

    } catch (error) {
      // Handle any errors here
      const errorMessage = error.response.data.message;
        dispatch({ type: types.GET_SINGLE_STUDENT_ERROR, payload: { message: errorMessage } });
    }
  };



// Logout action

export const authStudentLogout = () => async (dispatch) => {

    dispatch({ type: types.AUTH_STUDENT_LOGOUT });
};


// message reset

export const resetAuthMessage = () => (dispatch)=>{
  dispatch({type:types.RESET_AUTH_MESSAGE})
};




