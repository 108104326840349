import React from "react";
import FirstSlider from "../Components/FirstSlider";
import "./Home.modules.css";
import Subject from "../Components/Subject";
import QandA from "../Components/QandA";
import ContactUs from "../Components/ContactUs";
import MainContactUs from "../Components/MainContactUs";
import Pricing from "../Components/pricing";

const Home = () => {
  return (
    <div className='HomeMainDiv'>
      <FirstSlider />
      <Subject />
      <Pricing />
      <QandA />
      <ContactUs />
      {/* <MainContactUs /> */}
    </div>
  );
};

export default Home;
