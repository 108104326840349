"use client";

import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
  Link,
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";
import { Link as ReactRouterLink } from "react-router-dom";

function PriceWrapper(props) {
  const { children } = props;

  return (
    <Box
      mb={4}
      shadow='base'
      borderWidth='1px'
      alignSelf={{ base: "center", lg: "flex-start" }}
      borderColor={useColorModeValue("gray.200", "gray.500")}
      borderRadius={"xl"}
    >
      {children}
    </Box>
  );
}

export default function ThreeTierPricing() {
  return (
    <Box
      py={12}
      id='pricing'
    >
      <VStack
        spacing={2}
        textAlign='center'
      >
        <Heading
          as='h1'
          fontSize='4xl'
        >
          Tuition fee that suits your budget
        </Heading>
        <Text
          fontSize='lg'
          color={"gray.500"}
        >
          Hire someone within a minute, and if you're not satisfied with their
          services, you can raise your concerns and receive a refund within 14
          days.
        </Text>
      </VStack>
      <Stack
        direction={{ base: "column", md: "row" }}
        textAlign='center'
        justify='center'
        spacing={{ base: 4, lg: 10 }}
        py={10}
      >
        <PriceWrapper>
          <Box
            py={4}
            px={12}
          >
            <Text
              fontWeight='500'
              fontSize='2xl'
            >
              Basic Price
            </Text>
            <HStack justifyContent='center'>
              <Text
                fontSize='3xl'
                fontWeight='600'
              >
                ₹
              </Text>
              <Text
                fontSize='5xl'
                fontWeight='900'
              >
                500
              </Text>
              <Text
                fontSize='3xl'
                color='gray.500'
              >
                /month
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue("gray.50", "gray.700")}
            py={4}
            borderBottomRadius={"xl"}
          >
            <List
              spacing={3}
              textAlign='start'
              px={12}
            >
              <ListItem>
                <ListIcon
                  as={FaCheckCircle}
                  color='green.500'
                />
                Online Attendance
              </ListItem>
              <ListItem>
                <ListIcon
                  as={FaCheckCircle}
                  color='green.500'
                />
                Help and support 24/7
              </ListItem>
              <ListItem>
                <ListIcon
                  as={FaCheckCircle}
                  color='green.500'
                />
                Grade 1st to 5th
              </ListItem>
            </List>
            <Box
              w='80%'
              pt={7}
            >
              <Button
                w='full'
                colorScheme='red'
                variant='outline'
                as={ReactRouterLink}
              >
                <Link
                  as={ReactRouterLink}
                  to='/checkout'
                >
                  Hire now
                </Link>
              </Button>
            </Box>
          </VStack>
        </PriceWrapper>

        <PriceWrapper>
          <Box position='relative'>
            <Box
              position='absolute'
              top='-16px'
              left='50%'
              style={{ transform: "translate(-50%)" }}
            >
              <Text
                textTransform='uppercase'
                bg={useColorModeValue("red.300", "red.700")}
                px={3}
                py={1}
                color={useColorModeValue("gray.900", "gray.300")}
                fontSize='sm'
                fontWeight='600'
                rounded='xl'
              >
                Most Popular
              </Text>
            </Box>
            <Box
              py={4}
              px={12}
            >
              <Text
                fontWeight='500'
                fontSize='2xl'
              >
                Medium Price
              </Text>
              <HStack justifyContent='center'>
                <Text
                  fontSize='3xl'
                  fontWeight='600'
                >
                  ₹
                </Text>
                <Text
                  fontSize='5xl'
                  fontWeight='900'
                >
                  1500
                </Text>
                <Text
                  fontSize='3xl'
                  color='gray.500'
                >
                  /month
                </Text>
              </HStack>
            </Box>
            <VStack
              bg={useColorModeValue("gray.50", "gray.700")}
              py={4}
              borderBottomRadius={"xl"}
            >
              <List
                spacing={3}
                textAlign='start'
                px={12}
              >
                <ListItem>
                  <ListIcon
                    as={FaCheckCircle}
                    color='green.500'
                  />
                  Online Attendance
                </ListItem>
                <ListItem>
                  <ListIcon
                    as={FaCheckCircle}
                    color='green.500'
                  />
                  Help and support 24/7
                </ListItem>
                <ListItem>
                  <ListIcon
                    as={FaCheckCircle}
                    color='green.500'
                  />
                  Grade 6th to 10th
                </ListItem>
              </List>
              <Box
                w='80%'
                pt={7}
              >
                <Button
                  w='full'
                  colorScheme='red'
                  as={ReactRouterLink}
                >
                  <Link
                    as={ReactRouterLink}
                    to='/checkout'
                  >
                    Hire now
                  </Link>
                </Button>
              </Box>
            </VStack>
          </Box>
        </PriceWrapper>
        <PriceWrapper>
          <Box
            py={4}
            px={12}
          >
            <Text
              fontWeight='500'
              fontSize='2xl'
            >
              Other Price
            </Text>
            <HStack justifyContent='center'>
              <Text
                fontSize='3xl'
                fontWeight='600'
              >
                ₹
              </Text>
              <Text
                fontSize='5xl'
                fontWeight='900'
              >
                3000
              </Text>
              <Text
                fontSize='3xl'
                color='gray.500'
              >
                /month
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue("gray.50", "gray.700")}
            py={4}
            borderBottomRadius={"xl"}
          >
            <List
              spacing={3}
              textAlign='start'
              px={12}
            >
              <ListItem>
                <ListIcon
                  as={FaCheckCircle}
                  color='green.500'
                />
                Online Attendance
              </ListItem>
              <ListItem>
                <ListIcon
                  as={FaCheckCircle}
                  color='green.500'
                />
                Help and support 24/7
              </ListItem>
              <ListItem>
                <ListIcon
                  as={FaCheckCircle}
                  color='green.500'
                />
                Grade 11th to 12th
              </ListItem>
            </List>
            <Box
              w='80%'
              pt={7}
            >
              <Button
                w='full'
                colorScheme='red'
                variant='outline'
                as={ReactRouterLink}
              >
                <Link
                  as={ReactRouterLink}
                  to='/checkout'
                >
                  Hire now
                </Link>
              </Button>
            </Box>
          </VStack>
        </PriceWrapper>
      </Stack>
    </Box>
  );
}
