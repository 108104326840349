import { Box, Button, Image, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";

import aiImage from "../../Images/ai.jpg";
import { gradeData, SubjectData } from "../../Data/Dummy";
import { Link } from "react-router-dom";

const ServicePage = () => {
  return (
    <div
      style={{
        marginTop: "80px",
        minHeight: "100vh",
      }}
      overflow={"hidden"}
    >
      <Box position={"relative"}>
        <Box
          maxH={"80"}
          overflow={"hidden"}
        >
          <Image
            src={aiImage}
            width={"100%"}
            height={"100%"}
          />
        </Box>
        <Box
          position={"absolute"}
          top={"50%"}
          left={"50%"}
          transform={"translate(-50%,-50%)"}
          backdropBlur={"lg"}
          backgroundColor={"blackAlpha.600"}
          blur={"3xl"}
          textColor={"white"}
          width={"100%"}
          height={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Text
            mt={{ base: "25px", md: "25px", lg: "30px" }}
            fontFamily={"sans-serif"}
            textAlign={"center"}
            fontSize={{ base: "16px", md: "28px", lg: "36px" }}
            fontWeight={"800"}
          >
            Our Service
          </Text>
        </Box>
      </Box>
      <Box>
        <Box
          w={"80%"}
          mx={"auto"}
        >
          <Text
            fontSize={"2xl"}
            fontWeight={"bold"}
            py={"10"}
          >
            We offer home tuition for students across various grade levels.
          </Text>
          <SimpleGrid columns={{ base: 2, sm: 2, md: 2, lg: 4 }}>
            {gradeData.map((ele) => (
              <Box
                h='200px'
                boxShadow='rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px'
              >
                <Box
                  className='card'
                  h='100px'
                  w='100px'
                  borderRadius={"5px"}
                  m='auto'
                  mt='20px'
                  boxShadow='rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px'
                  padding='10px'
                >
                  <Image
                    src={ele.image}
                    w='100%'
                    h='100%'
                  />
                </Box>
                <Text
                  cursor='pointer'
                  _hover={{ color: "blue" }}
                  fontFamily='sans-serif'
                  fontSize={{
                    base: "19px",
                    sm: "19px",
                    md: "21px",
                    lg: "18px",
                  }}
                  textAlign={"center"}
                  mt='20px'
                >
                  {ele.title}
                </Text>
              </Box>
            ))}
          </SimpleGrid>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            mt={"6"}
          >
            <Link
              to='/checkout'
              colorScheme='blue'
            >
              <Button colorScheme='blue'>Hire a teacher</Button>
            </Link>
          </Box>
        </Box>
        <Box
          w={"80%"}
          mx={"auto"}
        >
          <Text
            fontSize={"2xl"}
            fontWeight={"bold"}
            py={"10"}
          >
            Our teachers offers classes across various subjects
          </Text>
          <SimpleGrid columns={{ base: 2, sm: 2, md: 2, lg: 4 }}>
            {SubjectData.map((ele) => (
              <Box
                h='200px'
                boxShadow='rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px'
              >
                <Box
                  className='card'
                  h='100px'
                  w='100px'
                  borderRadius={"5px"}
                  m='auto'
                  mt='20px'
                  boxShadow='rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px'
                  padding='10px'
                >
                  <Image
                    src={ele.image}
                    w='100%'
                    h='100%'
                  />
                </Box>
                <Text
                  cursor='pointer'
                  _hover={{ color: "blue" }}
                  fontFamily='sans-serif'
                  fontSize={{
                    base: "19px",
                    sm: "19px",
                    md: "21px",
                    lg: "18px",
                  }}
                  textAlign={"center"}
                  mt='20px'
                >
                  {ele.title}
                </Text>
              </Box>
            ))}
          </SimpleGrid>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            mt={"6"}
          >
            <Link
              to='/checkout'
              colorScheme='blue'
            >
              <Button colorScheme='blue'>Hire a teacher</Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default ServicePage;
